import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import sliderpreviousIcon from "../assets/img/slider-previous-icon.svg";
import slidernextIcon from "../assets/img/slider-next-icon.svg";


function Home() {

  // useEffect(()=>{
  //   document.title = 'Elixir | Home';
  // }, []);

  // Scroll Counter Up Start
  const [counterOn, setCounterState] = useState(false); 
  // Scroll Counter Up End

  // Project Slider Start
  const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={sliderpreviousIcon} className="img-fluid" alt="elixir" />
    </div>
  );
  
  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={slidernextIcon} className="img-fluid" alt="elixir" />
    </div>
  );

  
  // Team Slider Start
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,  
    focusOnSelect: true,
    pauseOnHover:false,  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  // Team Slider End

  // Testimonials Slider Start
  var settings3 = {
    dots: true,
    infinite: true,
    speed: 600,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    // prevArrow: <PrevArrow />,
    // nextArrow: <NextArrow />,
    focusOnSelect: true,
    pauseOnHover:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  // Testimonials Slider End

  return (
    <>    
    <section className="homeCategory">
      <Container>
          <Row>            
            <Col lg={3} md={6}>
              <Link to="/Brandingandgraphicdesign" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Branding & Design</h4>
                <p>Creative Branding ad design tailored for impact.</p>
                <img src={require('../assets/img/home-banner-img-1.jpg')} className="img-fluid" alt="elixir"  />
              </Link>
            </Col>  
            <Col lg={6} md={6}>
              <div className="homeCategory-box2" data-aos="fade-up" data-aos-duration="3000">
                <Carousel fade>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-1.jpg')} className="img-fluid" alt="elixir" />
                    <Carousel.Caption>
                      {/* <h3>Elixir World</h3> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-2.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-3.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-4.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-5.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-6.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-7.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-8.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-9.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-10.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-11.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-12.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                  <Carousel.Item>
                  <img src={require('../assets/img/home-service-slider-13.jpg')} className="img-fluid" alt="elixir" />
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>          
            <Col lg={3} md={6}>
              <Link to="/signageoutdoor" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Signage <span>(Outdoor, Indoor, Industrial)</span></h4>
                <p>Informative, visible, eye-catching, directional, branded, readable.</p>
                <img src={require('../assets/img/home-banner-img-2.jpg')} className="img-fluid" alt="elixir"  />
              </Link>
            </Col>  
            <Col lg={6} md={6}>
              <Link to="/Eventservices" className="homeCategory-box3" data-aos="fade-up" data-aos-duration="3000">
                <h4 className="mb-3">Events & Exhibitions</h4>
                <img src={require('../assets/img/home-banner-img-3.jpg')} className="img-fluid" alt="elixir"  />
              </Link>
            </Col>
            <Col lg={3} md={6}>
              <Link to="/Webdesignservices" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Web & Social Media</h4>
                <p>Engaging, interactive, shareable, accessible, influential, dynamic.</p>
                <img src={require('../assets/img/home-banner-img-4.jpg')} className="img-fluid" alt="elixir"  />
              </Link>
            </Col>  
            <Col lg={3} md={6}>
              <Link to="/printingservices" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Printing & Packaging</h4>
                <p>Durable, functional, creative, branded, eco-friendly, high-quality.</p>
                <img src={require('../assets/img/home-banner-img-5.jpg')} className="img-fluid" alt="elixir"  />
              </Link>
            </Col>  
        </Row>
      </Container>
    </section>
    <section className="homeService">
      <Container>
        <div className="homeService-img" data-aos="fade-up" data-aos-duration="3000">
          <Row>  
            <Col lg={9} md={12}>      
            <div className="homeService-title">
              <h2 class="home-h2">WE ARE <br /><hr /><strong class="color-yellow">HERE</strong> FOR <strong class="color-gray">YOU</strong></h2>
              <p>Think Branding, Digital services, Exhibitions and Event Management and Corporate Gifts. Think Elixirworld. An award winning, ISO 9001 certified design agency, with a pan gulf network.</p>
              <p>We are the only integrated creative hub in the GCC offering extensive in-house capabilities. Located strategically in Bahrain, KSA and UAE, our customer-centric teams work across multidimensional platforms.</p>
              <p>Our clients include the most quality-focused international brands in the region, with which we have partnered for over 10 years.</p>
              <h6>Elixirworld, your local agency for world-class results.</h6>
              <h4>Quality and Commitment.</h4>
            </div>
            </Col>
            <Col lg={3} md={12}>              
              <ScrollTrigger className="homeCounter-counter" onEnter={()=> setCounterState(true)} onExit={()=> setCounterState(false)} >
                <h4>
                  <span className="media-count-value">{counterOn && <CountUp start={0} end={14} duration={1} delay={0}/>}K <span className="homeCounter-plus">+</span></span>                  
                  <span className="homeCounter-title">FOLLOWERS</span>
                </h4>
                <h4>
                <span className="media-count-value">{counterOn && <CountUp start={0} end={1600} duration={5} delay={0}/>} <span className="homeCounter-plus">+</span></span>                  
                  <span className="homeCounter-title">EXHIBITION STAND</span>  
                </h4>
                <h4>
                <span className="media-count-value">{counterOn && <CountUp start={0} end={450} duration={2} delay={0}/>} <span className="homeCounter-plus">+</span></span>
                  <span className="homeCounter-title">CLIENTS</span>  
                </h4>
                <h4>
                <span className="media-count-value">{counterOn && <CountUp start={0} end={60} duration={4} delay={0}/>} <span className="homeCounter-plus">+</span></span>
                  <span className="homeCounter-title">AWARDS</span>  
                </h4>
              </ScrollTrigger>  
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="latestWork">
      <Container>
        <Row>
          <Col lg={12}>
            <h2 data-aos="fade-up" data-aos-duration="3000">LATEST <span>PROJECTS</span></h2> 
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="/Branding1">
                <img src={require('../assets/img/work/work-img1.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="/Branding1">
                <img src={require('../assets/img/work/work-img2.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img3.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img4.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img5.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img6.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img7.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img8.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img9.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img10.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img11.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div class="latestWork-box">
              <Link to="#">
                <img src={require('../assets/img/work/work-img12.jpg')} className="img-fluid" alt="elixirworld"  />
                <div class="overlay">
                  <div class="text">
                    <h4>BEST</h4>
                    <p>Brand Identity</p>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="clientlogo">
    <Container>
      <Row>
        <Col lg={12}>
          <h2 data-aos="fade-up" data-aos-duration="3000">Our <span>Clients</span></h2>
          <Col lg={12} data-aos="zoom-in" data-aos-duration="3000">
          <Slider {...settings2}>
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-1.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-2.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-3.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-4.jpg')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-5.jpg')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-6.jpg')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-7.jpg')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-8.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-9.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-10.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-11.jpg')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-12.jpg')} className="img1 img-fluid" alt="elixir" />
            </div>  
          </Slider>
        </Col> 
        </Col>
      </Row>
    </Container>
    </section>
    </>
  );
}

export default Home;
